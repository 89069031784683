import React from "react";
import teacherIcon from "../../../assets/images/teacher-icon.svg";
import paymentIcon from "../../../assets/images/online-payment.svg";
import messagingIcon from "../../../assets/images/messaging.svg";
import crossPlatformIcon from "../../../assets/images/cross-platform.svg";
import guardianIcon from "../../../assets/images/guardian.svg";
import resultIcon from "../../../assets/images/result-processing.svg";

export const Features = () => {
  return (
    <section className="features" id="features">
      <div className="container">
        <h3 className="title">Features</h3>
        <div className="features-grid">
          <div className="feature">
            <div className="feature-icon">
              <img src={paymentIcon} alt="Teacher" />
            </div>
            <h4>Online Payment</h4>
            <p>
              Provides students and guardians with the ability to make payments
              online through an easy, reliable, and secure payment channel. The
              administrative body can also view and monitor payments online
              which includes completed and outstanding payments.
            </p>
          </div>

          <div className="feature">
            <div className="feature-icon">
              <img src={guardianIcon} alt="Teacher" />
            </div>
            <h4>Guardians Accessiblity</h4>
            <p>
              The ability for the person with responsibility and legal right to
              a student to have access and benefit from the web application.
              Also providing the opportunity to easily monitor a student’s
              performance and involvement in other school activities.
            </p>
          </div>

          <div className="feature">
            <div className="feature-icon">
              <img src={resultIcon} alt="Teacher" />
            </div>
            <h4>Result Processing</h4>
            <p>
              To automate the existing manual result processing system, for an
              effective and efficient result computation. The digitized process
              provides capabilities such as a centralized repository for
              storage, management, and dissemination of result information to
              those concerned.
            </p>
          </div>

          <div className="features-grid-sub">
            <div className="feature">
              <div className="feature-icon">
                <img src={messagingIcon} alt="Teacher" />
              </div>
              <div className="feature-text">
                <h4>In-App Messaging</h4>
                <p>
                  Makes it possible for system users, which includes the
                  administrators, teachers, students, and guardians to interact
                  and share important information through the web application.
                  This improves how users communicate with each other through
                  the system and improve the learning process.
                </p>
              </div>
            </div>

            <div className="feature">
              <div className="feature-icon">
                <img src={crossPlatformIcon} alt="Teacher" />
              </div>
              <div className="feature-text">
                <h4>Cross Platform</h4>
                <p>
                  Designed to work across several devices as well as mobile
                  platforms such as Windows, Mac OS, Android, or iOS to enable
                  the users to have easy access to the software and make working
                  remotely more convenient.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
