import React from "react";
import BigHero from "../../components/big-hero";
import {
  Features,
  FAQs,
  HowItWorks,
  PaymentPlans,
  Testimonials,
} from "../../components/home";
import Footer from "../../components/footer";

export default function Home() {
  return (
    <>
      <BigHero />
      <Features />
      <PaymentPlans />
      <HowItWorks />
      <Testimonials />
      <FAQs />
      <Footer />
    </>
  );
}
