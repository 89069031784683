import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-2.png";

export default function Logo2() {
  return (
    <Link to="/">
      <div className="logo">
        <img src={logo} alt="oxymatt" />
      </div>
    </Link>
  );
}
