import React from "react";
import { FiFacebook, FiTwitter, FiLinkedin, FiInstagram } from "react-icons/fi";
import Logo2 from "../../components/logo-2";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        {/* <div className="logo-copyright">
          <Logo2 />
          <p className="copyright">2021 Oxymatt. All rights reserved.</p>
        </div> */}
        <div className="logo">
          <Logo2 />

          <div className="m-copyright">
            <p className="copyright">2021 Oxymatt. All rights reserved.</p>
          </div>
        </div>

        <div className="nav">
          <ul className="nav-list">
            <li>
              <a href="http://examsmicrobridge.com/sign-up" className="href">
                Create account
              </a>
            </li>
            <li>
              <a href="http://examsmicrobridge.com/login" className="href">
                Log in
              </a>
            </li>
            <li>
              <a href="https://www.microbridge.com.ng" className="href">
                About us
              </a>
            </li>
            <li>
              <a href="mailto:info@oxymatt.com" className="href">
                Contact us
              </a>
            </li>
          </ul>

          <div className="social-media">
            <FiInstagram />
            <FiFacebook />
            <FiTwitter />
            <FiLinkedin />
          </div>
        </div>
      </div>
    </footer>
  );
}
