import React from "react";
import { withRouter } from "react-router-dom";
import Toolbar from "../navigation/toolbar";

function Layout(props) {
  return (
    <React.Fragment>
      {/* <Toolbar /> */}
      <main>{props.children}</main>
    </React.Fragment>
  );
}

export default withRouter(Layout);
