import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Header from './header';

const Toolbar = () => {

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  // Functions
  const setOpenHandler = () => {
    setIsOpen(!isOpen);
  }

  const screenWidthHandler = () => {
    let width = window.screen.width;
    setScreenWidth(width);
  }

  useEffect(() => {
    screenWidthHandler();

  }, [])

  return <Header isOpen={isOpen} clicked={setOpenHandler} screenWidth={screenWidth} />
}

export default withRouter(Toolbar);