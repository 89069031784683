import React from 'react';

export const HowItWorks = () => {
  return (
    <section className='how-it-works' id='pricing'>
      <div className="container">
        <h2>Ease Your Workflow</h2>
        <p>Managing your day-to-day activities easily with effective interaction between teachers, students, and parents. Oxymatt interface provides better insight into result processing, in-app messaging, cross-platform and so much more.</p>
        <a href="http://localhost:3000">Get started</a>
      </div>
    </section>
  );
}