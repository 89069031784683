import React from 'react';
import NavigationItems from '../../navigationItems';
import Logo from '../../../logo';

export default function Header(props) {

  let headerIconClasses = ['main-header__icon'];
  let mobileNavClasses = ["main-header__mobile-nav"];
  let isMobile = false;

  if (props.isOpen) {
    headerIconClasses.push("open");
    mobileNavClasses.push("show");
  }

  if (props.screenWidth < 600) {
    isMobile = true;
  }

  return (
    <header className="main-header">

      <div className="container">

        <Logo />

        <NavigationItems isOpen={props.isOpen} type={isMobile ? 'mobile' : null} />

        <button className="hamburger-button" onClick={props.clicked}>
          <div className={headerIconClasses.join(" ")}></div>
        </button>
      </div>

    </header>
  );
}