import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';

export const GenerateFAQList = ({ faqs, toggleFAQ }) => {
  return faqs.map(faq => (
    <li key={faq.id} className={faq.open && 'open'}>
      <h3>
        <span>{faq.question}</span>
        <span className='faq-toggle-btn' onClick={() => toggleFAQ(faq.id, faq.open)}>
          {faq.open ? <FiMinus size={32} /> : <FiPlus size={32} />}
        </span>
      </h3>
      {faq.open && <p className={faq.open && 'active'}>{faq.answer}</p>}
    </li>
  ));
}
