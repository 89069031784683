import React from "react";
import cardIcon from "../../../assets/images/Card.svg";

export const PaymentPlans = () => {
  return (
    <section className="payment-plans" id="pricing">
      <div className="container">
        <div className="text-container">
          <h2>Our Payment Plans</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            mollitia, molestiae quas vel sint commodi repudiandae consequuntur
            voluptatum laborum numquam blanditiis harum quisquam eius sed odit
            fugiat iusto fuga praesentium optio, eaque rerum! Provident
            similique accusantium nemo autem.
          </p>
        </div>

        <img src={cardIcon} alt="credit card" />
      </div>
    </section>
  );
};
