import React from "react";
import Toolbar from "../navigation/toolbar";

export default function BigHero() {
  return (
    <section className="big-hero">
      <div className="container big-hero-image">
        <Toolbar />
        <div className="big-hero-text">
          <h1>School Management With</h1>
          <h1 className="alt-color">Ease</h1>
          <p>
            Managing secondary, primary, and even nursery schools
            simultaneously. A user-friendly, efficient, and reliable web app.
          </p>
          <div className="big-hero-cta">
            <a
              className="button button--primary"
              href="http://examsmicrobridge.com/sign-up"
            >
              Create account
            </a>

            <a
              className="button button--outlined"
              href="http://examsmicrobridge.com/login"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
