import React, { useState } from 'react';
import { GenerateFAQList } from '../../../helpers';


export const FAQs = () => {

  const [faqs, setFAQs] = useState([
    {
      id: 1,
      question: 'Can Oxymatt be used through mobile smartphones?',
      answer: 'Yes. Oxymatt can work on different mobile phones, tablets, and other smart devices.',
      open: false
    },
    {
      id: 2,
      question: 'Can Oxymatt work on all browsers?',
      answer: 'Yes. Oxymatt web application can work on all browsers.',
      open: false
    },
    {
      id: 3,
      question: 'Who can use the Oxymatt web application?',
      answer: 'School Management(Admin), Teachers, Guardians, and Students.',
      open: false
    },
    {
      id: 4,
      question: 'How does Oxymatt improve user experience?',
      answer: 'Oxymatt is a fast-functioning web application and can be easily navigated through by a user.',
      open: false
    },
  ]);

  const toggleFAQ = (id, status) => {
    const clonedFAQs = [...faqs];

    clonedFAQs.map(faq => {
      if (id === faq.id) {
        faq.open = !status;
      }
      return true;
    });

    setFAQs(clonedFAQs);
  }

  return (
    <section className="faqs">
      <div className="container">
        <h3 className='title'>Frequently Asked Questions</h3>
        <ul className="faqs-list">
          <GenerateFAQList faqs={faqs} toggleFAQ={toggleFAQ} />
        </ul>
      </div>
    </section>
  )
}
