import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import person from "../../../assets/images/photo.jpeg";
import { VscChevronLeft } from "react-icons/vsc";
import { VscChevronRight } from "react-icons/vsc";
import arrow from "../../../assets/images/testimonial.svg";
import principal1 from "../../../assets/images/principal1.png";
import principal from "../../../assets/images/principal.png";

export const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="container">
        <h3 className="title">Testimonials</h3>
        <div className="titles">
          <div className="titleContainer">
            <h2 className="sub-title">
              What our <br /> <span className="alt-color">clients</span> have{" "}
              <br /> to say.
            </h2>
            <div className="icon">
              <img src={arrow} />
            </div>
          </div>

          <div className="carousel">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              interval={7000}
            >
              <div style={{ height: "300px" }}>
                <div className="profile">
                  <div className="content">
                    <div className="oval" />
                    <div className="profile-picture">
                      <img src={principal1} />
                    </div>
                    <div className="info">
                      <p className="info-name">Hamida Hassan</p>
                      <h4 className="info-title">
                        Headmaster - British High School
                      </h4>
                    </div>
                  </div>

                  <div className="message">
                    <p>
                      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatum corrupti asperiores, cupiditate molestiae autem
                      omnis exercitationem eaque tempora quas repellendus
                      alias."
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ height: "300px" }}>
                <div className="profile">
                  <div className="content">
                    <div className="oval" />
                    <div className="profile-picture">
                      <img
                        src={principal}
                        style={{ marginTop: "21px", marginLeft: "5px" }}
                      />
                    </div>
                    <div className="info">
                      <p className="info-name">Dj Ayubs</p>
                      <h4 className="info-title">Principal - Musical School</h4>
                    </div>
                  </div>

                  <div className="message">
                    <p>
                      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatum corrupti asperiores, cupiditate molestiae autem
                      omnis exercitationem eaque tempora quas repellendus
                      alias."
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ height: "300px" }}>
                <div className="profile">
                  <div className="content">
                    <div className="oval" />
                    <div className="profile-picture">
                      <img src={principal1} />
                    </div>
                    <div className="info">
                      <p className="info-name">Ibingibo Lolomari</p>
                      <h4 className="info-title">Principal - MB Academy</h4>
                    </div>
                  </div>

                  <div className="message">
                    <p>
                      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatum corrupti asperiores, cupiditate molestiae autem
                      omnis exercitationem eaque tempora quas repellendus
                      alias."
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ height: "300px" }}>
                <div className="profile">
                  <div className="content">
                    <div className="oval" />
                    <div className="profile-picture">
                      <img
                        src={principal}
                        style={{ marginTop: "21px", marginLeft: "5px" }}
                      />
                    </div>
                    <div className="info">
                      <p className="info-name">Isa M</p>
                      <h4 className="info-title">Principal - SM Academy</h4>
                    </div>
                  </div>

                  <div className="message">
                    <p>
                      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatum corrupti asperiores, cupiditate molestiae autem
                      omnis exercitationem eaque tempora quas repellendus
                      alias."
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ height: "300px" }}>
                <div className="profile">
                  <div className="content">
                    <div className="oval" />
                    <div className="profile-picture">
                      <img
                        src={principal}
                        style={{ marginTop: "21px", marginLeft: "5px" }}
                      />
                    </div>
                    <div className="info">
                      <p className="info-name">John Smith</p>
                      <h4 className="info-title">Principal - Some Academy</h4>
                    </div>
                  </div>

                  <div className="message">
                    <p>
                      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatum corrupti asperiores, cupiditate molestiae autem
                      omnis exercitationem eaque tempora quas repellendus
                      alias."
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};
