import React from "react";
import NavigationItem from "./navigationItem";

export default function NavigationItems(props) {
  let navList = null;

  navList = (
    <ul className="nav-list">
      <NavigationItem link="features">Features</NavigationItem>
      <li>
        <a href="mailto:info@oxymatt.com">Contact Us</a>
      </li>
      <NavigationItem link="faqs">FAQs</NavigationItem>
    </ul>
  );

  if (props.isOpen) {
    let mobileListClasses = ["mobile-nav-list"];
    if (props.isOpen) {
      mobileListClasses.push("show");
    }

    navList = (
      <ul className={mobileListClasses.join(" ")}>
        <NavigationItem link="features">Features</NavigationItem>
        <li>
          <a href="mailto:name@email.com">Contact Us</a>
        </li>
        <NavigationItem link="faqs">FAQs</NavigationItem>
      </ul>
    );
  }

  return navList;
}
